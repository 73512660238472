<!-- eslint-disable max-len -->
<template>
  <div class="relative h-full">

    <!-- Detail -->
    <div class="relative" :class="openConfigDrawer ? 'translate-x-0' : 'translate-x-full'">
      <div
        class="fixed top-0 left-0 bottom-0 right-0 bg-black transition-all duration-500 z-30"
        :class="!showOrderDetails ? 'invisible opacity-0' : 'visible opacity-60'"
      ></div>
      <div class="drawer" :class="showOrderDetails ? 'translate-x-0' : 'translate-x-full'">
        <div class="overflow-x-auto flew-grow">
          <header class="drawer-header flex items-center py-6 justify-between">
            <h3 class="uppercase tracking-widest text-sm font-semibold">
              Resumo do pedido
            </h3>
            <Button
              @click="closeOrderDetails"
              kind="plain"
              aria-label="Close"
              class="absolute top-4 right-6"
            >
              <XIcon size="24" />
              <span class="sr-only">Fechar</span>
            </Button>
          </header>
          <section v-if="orderSelected" class="divide-y-4 divide-gray-100">
            <div class="drawer-section pb-6">
              <div class="py-3">
                <div class="relative pl-16">
                  <div x-data="scrollProgress" class="absolute inline-flex items-center justify-center overflow-hidden rounded-full -top-2 -left-2">
                    <svg viewBox="0 0 42 42" class="donut w-16 h-16">
                      <circle
                        class="donut-hole"
                        cx="21"
                        cy="21"
                        r="15.91549430918954"
                        fill="transparent"
                      ></circle>
                      <circle
                        class="donut-ring text-primary-200"
                        cx="21"
                        cy="21"
                        r="15.91549430918954"
                        fill="transparent"
                        stroke="currentColor"
                        stroke-width="3"
                      ></circle>
                      <circle
                        class="donut-segment text-primary-700"
                        cx="21"
                        cy="21"
                        r="15.91549430918954"
                        fill="transparent"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-width="3"
                        :stroke-dasharray="installmentsPaidPercentage"
                        stroke-dashoffset="25"
                      ></circle>
                    </svg>
                    <span class="absolute text-xs font-semibold text-primary-700">
                      {{ installmentsPaidPercentage[0] }}%
                    </span>
                  </div>
                  <div>
                    <div class="text-gray-500 text-sm">Faturas pagas</div>
                    <div>
                      {{ orderSelected.installmentsPaid }}/{{
                        orderSelected.installmentsTotal
                      }}
                      parcelas
                    </div>
                  </div>
                </div>
              </div>
              <dl class="divide-y divide-gray-200">
                <div class="grid grid-cols-3 gap-4 py-4">
                  <dt class="text-gray-500">Nº do pedido</dt>
                  <dd class="col-span-2">
                    {{ orderSelected.orderId }}
                  </dd>
                </div>
                <div class="grid grid-cols-3 gap-4 py-4">
                  <dt class="text-gray-500">Status</dt>
                  <dd class="col-span-2">
                    <Badge
                      :kind="badgeKindStatus[orderSelected.status] || 'default'"
                    >
                      {{ statusOptions[orderSelected.status] }}
                    </Badge>
                  </dd>
                </div>
                <div class="grid grid-cols-3 gap-4 py-4">
                  <dt class="text-gray-500">Repasse</dt>
                  <dd class="col-span-2">
                    <Badge
                      :kind="
                        badgeKindPaymentStatus[orderSelected.paymentStatus] ||
                        'default'
                      "
                      chip
                    >
                      {{ paymentStatusOptions[orderSelected.paymentStatus] }}
                    </Badge>
                  </dd>
                </div>
                <div class="grid grid-cols-3 gap-4 py-4">
                  <dt class="text-gray-500">Etapa da compra</dt>
                  <dd class="col-span-2">
                    {{ this.getStep(orderSelected.step) }}
                  </dd>
                </div>
                <div class="grid grid-cols-3 gap-4 py-4">
                  <dt class="text-gray-500">Data da compra</dt>
                  <dd class="col-span-2">
                    {{ orderSelected.createdAt | dateFilter }}
                  </dd>
                </div>
                <div class="grid grid-cols-3 gap-4 py-4">
                  <dt class="text-gray-500">Seller</dt>
                  <dd class="col-span-2">
                    {{ orderSelected.sellers }}
                  </dd>
                </div>
              </dl>
            </div>

            <div class="drawer-section py-6">
              <h3 class="uppercase tracking-widest text-sm font-semibold mb-6">
                Dados do comprador
              </h3>

              <dl class="divide-y divide-gray-200">
                <div class="grid grid-cols-3 gap-4 py-4">
                  <dt class="text-gray-500">Nome</dt>
                  <dd class="col-span-2">{{ orderSelected.clientName }}</dd>
                </div>
                <div class="grid grid-cols-3 gap-4 py-4">
                  <dt class="text-gray-500">CPF</dt>
                  <dd class="col-span-2">
                    {{ orderSelected.clientCPF | cpfFilter }}
                  </dd>
                </div>
                <div class="grid grid-cols-3 gap-4 py-4">
                  <dt class="text-gray-500">Email</dt>
                  <dd class="col-span-2">{{ orderSelected.clientEmail }}</dd>
                </div>
                <div class="grid grid-cols-3 gap-4 py-4">
                  <dt class="text-gray-500">Celular</dt>
                  <dd class="col-span-2">
                    {{ orderSelected.clientPhone | phoneFilter }}
                  </dd>
                </div>
              </dl>
            </div>

            <div class="drawer-section py-6">
              <div class="flex items-center mb-6 justify-between">
                <h3 class="uppercase tracking-widest text-sm font-semibold">
                  Itens do pedido
                </h3>
                <span class="text-sm text-gray-500">
                  {{ orderSelected.productAmount }} item
                </span>
              </div>

              <ul class="rounded-xl border divide-y divide-gray-200">
                <li
                  v-for="(product, index) in orderSelected.products"
                  :key="index"
                  class="p-4"
                >
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <img
                        class="w-btn"
                        :srcset="product.image || `${imageDefault2x} 2x`"
                        :src="product.image || imageDefault"
                      />
                    </div>
                    <div class="flex-grow pl-4">
                      <div class="flex justify-between">
                        <div class="text-sm">
                          {{ product.name }}
                        </div>
                        <div class="text-sm text-right">
                          {{ product.value | moneyFilter }}
                        </div>
                      </div>
                      <div class="text-xs text-gray-500">
                        Qtd: {{ product.amount }}
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  class="flex justify-between bg-gray-100 p-4 rounded-b-xl text-sm"
                >
                  <div class="text-gray-500">Total</div>
                  <div class="font-semibold text-right">
                    {{ orderSelected.productPrice | moneyFilter }}
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </div>
        <div v-if="orderSelected && orderSelected.status === 'OPEN' && orderSelected.showDownloadBilletsButton" class="drawer-footer sm:flex sm:justify-end">
          <Button :kind="areBilletsDownloading ? 'loading' : 'primary-subtle w-full sm:w-auto'" @click="downloadBillets">
            <template v-if="areBilletsDownloading">Baixando...</template>
            <template v-else>
              <DownloadIcon size="24" />
              Baixar boletos
            </template>
          </Button>
        </div>
      </div>
    </div>

    <Sidenav
      @sidenavOpen="handleSidenavOpen"
      @onChangeRetailer="handleChangeRetailer"
      :opened="sidenavOpen"
    />

    <!-- Filter -->
    <div class="relative" :class="openConfigDrawer ? 'translate-x-0' : 'translate-x-full'">
      <div
        class="fixed top-0 left-0 bottom-0 right-0 bg-black transition-all duration-500 z-30"
        :class="!openConfigDrawer ? 'invisible opacity-0' : 'visible opacity-60'"
      ></div>
      <div class="drawer" :class="openConfigDrawer ? 'translate-x-0' : 'translate-x-full'">
        <div class="py-6 flex-grow overflow-x-auto">
          <header class="drawer-header flex items-center justify-between">
            <h3 class="font-bold text-lg">
              Filtros
            </h3>
            <button
              aria-label="Close"
              class="button button-plain button-icon hover:border-gray-200 -mr-3"
              @click="showConfigDrawer"
            >
              <XIcon size="24"/>
              <span class="sr-only">
                Fechar
              </span>
            </button>
          </header>
          <div class="pt-9 space-y-9">
            <div class="drawer-section">
              <h4 class="uppercase tracking-widest text-sm font-semibold mb-5">
                Status
              </h4>
              <ul class="space-y-3">
                <li  v-for="([value, status], index) in Object.entries(statusOptions)"
                :key="index"
                @click="filterHandleStatusSelect(value)">
                  <label class="flex justify-between bg-gray-50 rounded-md py-3 px-4">
                    <span>{{ status }}</span>
                    <input
                      type="radio"
                      name="filterStatusSelected"
                      class="radio-button"
                      :checked="value === filterStatusSelected"
                    >
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div class="pt-9 space-y-9">
            <div class="drawer-section">
              <h4 class="uppercase tracking-widest text-sm font-semibold mb-5">
                Repasse
              </h4>
              <ul class="space-y-3">
                <li v-for="([value, status], index) in Object.entries(paymentStatusOptions)"
                :key="index"
                @click="filterHandlePaymentStatusSelect(value)">
                  <label class="flex justify-between bg-gray-50 rounded-md py-3 px-4">
                    <span>{{ status }}</span>
                    <input
                      type="radio"
                      name="filterPaymentStatusSelected"
                      class="radio-button"
                      :checked="value === filterPaymentStatusSelected"
                    >
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="drawer-footer">
          <button class="button button-primary w-full" @click="applyFilter">
            Aplicar
          </button>
        </div>
      </div>
    </div>

    <div class="relative lg:ml-64 flex flex-col min-h-full">
      <Topbar @sidenavOpen="handleSidenavOpen" pageName="Pedidos" />

      <header class="border-b-2 border-gray-200 bg-white">
        <div
          class="flex flex-col lg:flex-row gap-4 lg:gap-5 px-4 lg:px-6 py-3 lg:pb-5"
        >
          <div class="flex flex-grow gap-3 items-center">
            <div
              class="flex-shrink-0 w-btn h-btn flex items-center justify-center rounded-xl bg-primary-100 text-primary-600"
            >
              <ShoppingCartIcon size="24" />
            </div>
            <h1 class="font-bold text-xl">Pedidos</h1>
          </div>
          <div class="hidden lg:block flex-shrink-0"></div>
        </div>
      </header>

      <div
        class="flex gap-3 lg:gap-5 py-5 px-4 lg:px-6 bg-white"
      >
        <div class="flex flex-grow gap-5">
          <div class="w-full lg:w-72">
            <div class="input-group has-icon">
              <div class="input-control">
                <div class="input-icon">
                  <SearchIcon size="24" />
                </div>
                <input
                  v-on:keyup.enter="filterBySearch('enter')"
                  v-on:keyup="waitTypingFinish"
                  v-model="searchInput"
                  type="text"
                  class="input-field"
                  placeholder="Nome, CPF, nº pedido..."
                />
              </div>
            </div>
          </div>
          <div class="hidden lg:block">
            <Select @select="handleStatusSelect" label="Status" white>
            <Option
              v-for="([value, status], index) in Object.entries(statusOptions)"
              :key="index"
              :value="value"
            >
              {{ status }}
            </Option>
          </Select>
          </div>
          <div class="hidden lg:block">
            <Select @select="handlePaymentStatusSelect" label="Repasse" white>
              <Option
                v-for="([value, paymentStatus], index) in Object.entries(
                  paymentStatusOptions
                )"
                :key="index"
                :value="value"
              >
                {{ paymentStatus }}
              </Option>
            </Select>
          </div>
        </div>
        <div class="hidden lg:block flex-shrink-0">
          <DatePicker
            class="w-80"
            v-model="selectedDates"
            @input="handleDatePick"
            :defaultDate="defaultDate"
            placeholder="Selecionar"
            mode="range"
            maxDate="today"
            icon
            white
          />
        </div>
        <div class="flex gap-3 flex-shrink-0 lg:hidden">
          <button class="button button-icon" @click="showConfigDrawer">
            <AdjustmentsIcon size="24"/>
          </button>
          <button class="button button-icon" id="datePickerToggle">
            <CalendarIcon size="24"/>
          </button>
        </div>
      </div>

      <main class="relative lg:px-6 flex-grow overflow-x-auto">
        <div
          class="bg-white bg-opacity-90 absolute top-0 right-0 left-0 bottom-0 z-10 flex flex-col items-center pt-36"
          :class="{ hidden: !searchingOrders }"
        >
          <svg
            role="status"
            class="w-12 h-12 mr-2 text-gray-300 animate-spinner"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            ></path>
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="#20627A"
            ></path>
          </svg>
        </div>

        <Table
          :headers="tableHeaders"
          v-model="currentSort"
          @sort="fillOrders"
          class=""
        >
          <tr v-for="(order, index) in orders" :key="index">
            <td class="max-w-xs">
              <div class="flex gap-3">
                <div class="flex-shrink-0">
                  <img
                    class="w-btn"
                    :srcset="order.products[0].image || `${imageDefault2x} 2x`"
                    :src="order.products[0].image || imageDefault"
                  />
                </div>
                <div class="w-60 lg:w-80">
                  <div class="font-semibold truncate">
                    {{ order.productName }}
                  </div>
                  <div class="text-xs text-gray-500 font-semibold mt-0.5">
                    {{ order.orderId }}
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="text-sm">{{ order.createdAt | dateFilter }}</div>
              <div class="text-xs text-gray-500">
                {{ order.createdAt | hourFilter }}
              </div>
            </td>
            <td>
              <Badge :kind="badgeKindStatus[order.status] || 'default'">
                {{ statusOptions[order.status] }}
              </Badge>
            </td>
            <td>
              <Badge
                :kind="badgeKindPaymentStatus[order.paymentStatus] || 'default'"
                chip
              >
                {{ paymentStatusOptions[order.paymentStatus] }}
              </Badge>
            </td>
            <td class="text-right">{{ order.value | moneyFilter }}</td>
            <td class="text-right">
              <button
                @click="() => openOrderDetails(order)"
                class="button button-plain button-icon text-gray-500 hover:border-gray-200"
                type="button"
              >
                <EyeIcon size="24" />
              </button>
            </td>
          </tr>
          <tr v-if="orders.length === 0">
            <td colspan="100%" class="text-center">
              <div class="py-4">Nenhum resultado encontrado</div>
            </td>
          </tr>
        </Table>
      </main>

      <footer class="bg-white lg:sticky lg:bottom-0 z-9 lg:px-6">
        <Pagination
          v-model="currentPage"
          :itemsPerPage="ordersPerPage"
          :numberOfItems="totalOfOrders"
          @pagination="fillOrders"
        />
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  SearchIcon,
  CalendarIcon,
  EyeIcon,
  XIcon,
  AdjustmentsIcon,
  ShoppingCartIcon,
  DownloadIcon,
} from '@vue-hero-icons/outline';
import moment from 'moment';
import PDFMerger from 'pdf-merger-js/browser';

import {
  Badge,
  Button,
  DatePicker,
  Option,
  Pagination,
  Select,
  Table,
} from '@/components';
import StringMixin from '@/mixins/string';
import Sidenav from '@/feature/Sidenav.vue';
import Topbar from '@/feature/Topbar.vue';
import OrdersService from '../orders.service';
import { CasaEVideoRetailerIds, LeBiscuitRetailerIds } from '../../../util/util.retailers';

moment.locale('pt-BR');

export default {
  name: 'orders',
  metaInfo: {
    title: 'Pedidos',
  },
  mixins: [StringMixin],
  components: {
    Badge,
    Button,
    DatePicker,
    EyeIcon,
    Option,
    Pagination,
    SearchIcon,
    Select,
    Sidenav,
    Table,
    Topbar,
    XIcon,
    ShoppingCartIcon,
    AdjustmentsIcon,
    CalendarIcon,
    DownloadIcon,
  },
  data: () => ({
    imageDefault:
      'https://cdn.parcelex.com.br/assets/v3/icons/oder-placeholder-image.png',
    imageDefault2x: 'https://cdn.parcelex.com.br/assets/v3/icons/oder-placeholder-image@2x.png',
    selectedDates: [],
    defaultDate: [],
    searchInput: '',
    statusSelected: '',
    paymentStatusSelected: '',
    currentSearchTimeout: null,
    maxSearchTimeout: 800,
    searchingOrders: false,
    orderSelected: null,
    showOrderDetails: false,
    sidenavOpen: false,
    openConfigDrawer: false,
    filterStatusSelected: '',
    filterPaymentStatusSelected: '',
    badgeKindPaymentStatus: {
      CHARGEBACK: 'info',
      NOT_PAID: 'warning',
      PAID: 'success',
    },
    paymentStatusOptions: {
      '': 'Todos',
      CHARGEBACK: 'Descontado',
      NOT_PAID: 'Não Realizado',
      PAID: 'Realizado',
    },
    badgeKindStatus: {
      ABANDONED: 'danger',
      CANCELED: 'danger',
      CLOSED: 'default',
      OPEN: 'success',
      PENDING: 'warning',
    },
    statusOptions: {
      '': 'Todos',
      ABANDONED: 'Expirado',
      CANCELED: 'Cancelado',
      CLOSED: 'Fechado',
      OPEN: 'Contratado',
      PENDING: 'Pendente',
    },
    tableHeaders: [
      { text: 'Pedido' },
      {
        text: 'Data da compra',
        sortable: true,
        sortFunctions: {
          ascending: { createdAt: 1 },
          descending: { createdAt: -1 },
        },
      },
      {
        text: 'Status',
        sortable: true,
        sortFunctions: {
          ascending: { status: 1 },
          descending: { status: -1 },
        },
      },
      {
        text: 'Repasse',
        sortable: true,
        sortFunctions: {
          ascending: { paymentStatus: 1 },
          descending: { paymentStatus: -1 },
        },
      },
      {
        text: 'Valor',
        sortable: true,
        sortFunctions: {
          ascending: { productPrice: 1 },
          descending: { productPrice: -1 },
        },
      },
      {
        text: 'Visualizar',
        srOnly: true,
      },
    ],
    ordersPerPage: 15,
    currentPage: 0,
    currentSort: {
      headerIndex: 1,
      direction: 'descending',
    },
    areBilletsDownloading: false,
  }),
  computed: {
    ...mapState('retailer', ['currentRetailer']),
    ...mapState('orders', ['orders', 'totalOfOrders']),
    ...mapState('auth', ['userId', 'hierarchy']),
    installmentsPaidPercentage() {
      const { installmentsPaid, installmentsTotal } = this.orderSelected;
      const installmentsPaidPercentage = (installmentsPaid / installmentsTotal) * 100;
      return [installmentsPaidPercentage.toFixed(), 100];
    },
  },
  methods: {
    ...mapActions('loading', ['setIsLoading']),
    ...mapActions('orders', ['getOrders']),
    handleSidenavOpen() {
      this.sidenavOpen = !this.sidenavOpen;
    },
    async handleChangeRetailer() {
      await this.fillOrders();
    },
    async handleStatusSelect(newValue) {
      if (newValue === this.statusSelected) return;
      this.statusSelected = newValue;
      await this.fillOrders();
    },
    async filterHandleStatusSelect(newValue) {
      if (newValue === this.filterStatusSelected) return;
      this.filterStatusSelected = newValue;
    },
    async handlePaymentStatusSelect(newValue) {
      if (newValue === this.paymentStatusSelected) return;
      this.paymentStatusSelected = newValue;
      await this.fillOrders();
    },
    async filterHandlePaymentStatusSelect(newValue) {
      if (newValue === this.filterPaymentStatusSelected) return;
      this.filterPaymentStatusSelected = newValue;
    },
    handleDatePick() {
      if (this.selectedDates.length === 2) this.fillOrders();
    },
    canViewAll(retailerId) {
      return (![...CasaEVideoRetailerIds, ...LeBiscuitRetailerIds]
        .includes(retailerId) || this.hierarchy === 'manager');
    },
    async fillOrders() {
      this.searchingOrders = true;
      const [startDate, endDate] = this.selectedDates;
      const { headerIndex, direction } = this.currentSort;
      const sortFunction = this.tableHeaders[headerIndex].sortFunctions[direction];

      if (this.currentRetailer && startDate instanceof Date && endDate instanceof Date) {
        const filter = !this.canViewAll(this.currentRetailer._id)
          ? {
            canViewAll: false,
            intraUserId: this.userId,
          }
          : {};
        await this.getOrders({
          retailerId: this.currentRetailer._id,
          startDate: moment(startDate).startOf('day').toISOString(),
          endDate: moment(endDate).endOf('day').toISOString(),
          search: this.searchInput,
          status: this.statusSelected,
          paymentStatus: this.paymentStatusSelected,
          limit: this.ordersPerPage,
          offset: this.currentPage,
          sort: JSON.stringify(sortFunction),
          ...filter,
        });
      }

      this.searchingOrders = false;
    },
    closeOrderDetails() {
      this.showOrderDetails = false;
      this.orderSelected = null;
    },
    openOrderDetails(transaction) {
      this.showOrderDetails = true;
      this.orderSelected = transaction;
    },
    async filterBySearch(type) {
      this.filterBySearchType = type;
      await this.fillOrders();
    },
    waitTypingFinish() {
      if (this.currentSearchTimeout) clearTimeout(this.currentSearchTimeout);

      this.currentSearchTimeout = setTimeout(() => {
        if (this.filterBySearchType === 'enter') {
          this.filterBySearchType = null;
          return;
        }
        this.filterBySearch('timeout');
      }, this.maxSearchTimeout);
    },
    getStep(step) {
      switch (step) {
        case 'REFUSED':
          return 'Pedido Negado';
        case 'COMPLETE':
          return 'Etapas concluídas';
        case 'FRONTDOC':
          return 'Aguardando envio da frente do documento';
        case 'BACKDOC':
          return 'Aguardando envio do verso do documento';
        case 'SELFIE':
          return 'Aguardando envio da selfie';
        case 'DESK':
          return 'Documentação em análise';
        case 'ENTRY':
          return 'Aguardando pagamento da entrada';
        case 'WAITING':
        case 'CONFIRMATION':
          return 'Aguardando verificação do celular';
        default:
          return 'Aguardando seleção do parcelamento';
      }
    },
    showConfigDrawer() {
      this.filterStatusSelected = this.statusSelected;
      this.filterPaymentStatusSelected = this.paymentStatusSelected;
      this.openConfigDrawer = !this.openConfigDrawer;
    },
    async applyFilter() {
      if (this.statusSelected !== this.filterStatusSelected) {
        this.statusSelected = this.filterStatusSelected;
      }

      if (this.paymentStatusSelected !== this.filterPaymentStatusSelected) {
        this.paymentStatusSelected = this.filterPaymentStatusSelected;
      }

      await this.fillOrders();

      this.openConfigDrawer = false;
    },
    async downloadBillets() {
      try {
        this.areBilletsDownloading = true;
        const { transactionId } = this.orderSelected;
        const merger = new PDFMerger();

        const billetList = await OrdersService.getInstallmentsBilletPDF({ transactionId });
        const promises = billetList.map(async (billet) => {
          await merger.add(Buffer.from(billet));
        });
        await Promise.all(promises);
        await merger.save(`boletos_${transactionId}`);
      } catch (error) {
        console.error('Erro ao baixar boletos');
        console.error(error);
      } finally {
        this.areBilletsDownloading = false;
      }
    },
  },
  async beforeMount() {
    this.defaultDate = [
      moment().subtract(1, 'month').format('DD/MM/YYYY'),
      moment().format('DD/MM/YYYY'),
    ];
  },
  async mounted() {
    this.selectedDates = [moment().subtract(1, 'month').toDate(), moment().toDate()];
    await this.setIsLoading({ isLoading: true });
    await this.fillOrders();
    await this.setIsLoading({ isLoading: false });
  },
};
</script>
